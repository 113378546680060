import React, { useEffect } from "react";
import Header from '../components/Header.jsx'; 
import Footer from '../components/Footer.jsx'; 
import PageBanner from '../components/PageBanner.jsx';
import SubscribeSection from '../components/SubscribeSection.jsx';

const AboutUs = () => {

    const ceos = [
        { name: "Jeremy Doman", imageUrl: "/images/pro1.png", position: "CEO" },
        { name: "Fiona Blumin", imageUrl: "/images/pro2.png", position: "Head of Strategy" }
      ];

      const isMobile = window.innerWidth < 768; // You can detect mobile screen size
      const backgroundImage = isMobile 
            ? "/images/about-mobile-banner.png" 
            : "/images/about-banner.png";
      const disableContextMenu = (e) => {
        e.preventDefault();
        };

    return (
        <>
        <title>About</title>
            <Header />
            <PageBanner
                heading="About Us"
                paragraph={
                    <span className="max-w-[335px] md:max-w-[560px] block">
                        Empowering cast, crew, and creatives by providing them with the technology and resources that will bring their production to life.
                    </span>
                }
                backgroundImage={backgroundImage} // Dynamically set the background image
                className="bg-cover bg-center"
                style={{ maxWidth: '560px' }}
            />
            <section className='mt-6 md:mt-[100px] px-[0px] md:px-[50px] lg:px-[100px]'>
            <div className='container mx-auto p-4 px-6 md:px-4 items-center max-w-[68rem] px-4 sm:px-6 lg:px-8'>
                <h2 class="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-5 font-sans tracking-[-2px]">Why CastMe?</h2>
                <p class=" mb-5 text-[16px] md:text-[20px] leading-[28px] md:leading-[30px] text-[#1A191E] font-inter font-[500]">We support every stakeholder in the audition process—whether you’re talent, a casting director, or a producer. We connect performers with trusted producers offering paid opportunities, catering to both union and non-union members. Our cutting-edge technology makes it easy for casting directors to filter talent and communicate effectively, ensuring a smooth casting experience. Beyond casting, we also help producers and creatives find grants and financing to bring their projects to life. With Cast Me, you gain access to a transparent, efficient marketplace built for everyone in the industry.</p>
            </div>
            </section>
            {/* <section className='mt-10'>
            <div className='container mx-auto p-4  items-center'>
                <h2 class="text-[30px] md:text-[40px] lg:text-[64px] font-[500] mb-4 font-sans text-center">Letter From Our founders</h2>
                <p class=" mb-5">We support every stakeholder in the audition process—whether you’re talent, a casting director, or a producer. We connect performers with trusted producers offering paid opportunities, catering to both union and non-union members. Our cutting-edge technology makes it easy for casting directors to filter talent and communicate effectively, ensuring a smooth casting experience. Beyond casting, we also help producers and creatives find grants and financing to bring their projects to life. With Cast Me, you gain access to a transparent, efficient marketplace built for everyone in the industry.</p>
            </div>
            </section> */}
           {/* <div className="py-[6.5rem] pb-[0px] px-[42px] md:px-[50px] lg:px-[100px]">
            <h2 className="text-[44px] md:text-[40px] lg:text-[64px] font-[500] mb-12 font-sans text-center tracking-[-2px] leading-[48px] md:leading-[70px]">
                Letter From Our Founders
            </h2>
            <div className="max-w-[55rem] mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-2 md:grid-cols-2 gap-10"> 
                {ceos.map((ceo, index) => (
                    <div key={index} className="flex flex-col items-center p-0 md:p-6 text-center">
                    <img
                        src={ceo.imageUrl}
                        alt={ceo.name}
                        className="max-w-[-webkit-fill-available] max-h-[-webkit-fill-available] w-[256px] rounded-[1rem] mb-4"
                    />
                    <h2 className="text-[16px] md:text-[20px] font-sans font-bold md:font-semibold">{ceo.name}</h2>
                    <p className="text-[12px] md:text-[18px] font-sans font-normal text-gray-400 mt-1">{ceo.position}</p>
                    </div>
                ))}
                </div>
            </div>
            </div> */}

            <div className="py-[6.5rem] md:py-[14rem] pb-[0px] md:pb-[0px] px-[42px] md:px-[50px] lg:px-[100px]">
                <h2 className="text-[44px] md:text-[40px] lg:text-[50px] font-[500] mb-8 font-sans text-center tracking-[-2px] leading-[48px] md:leading-[60px]">
                    Letter From Our Founders
                </h2>
                <div className="max-w-[46rem] mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-2 md:grid-cols-2 gap-10">
                        {ceos.map((ceo, index) => (
                            <div key={index} className="flex flex-col items-center p-0 md:p-6 text-center">
                                <img
                                    src={ceo.imageUrl}
                                    alt={ceo.name}
                                    className="w-[210px] h-auto rounded-[1rem] mb-4 object-cover border border-black"
                                    onContextMenu={disableContextMenu}
                                />
                                <h2 className="text-[16px] md:text-[18px] font-sans font-bold md:font-semibold">{ceo.name}</h2>
                                <p className="text-[12px] md:text-[16px] font-sans font-normal text-gray-400 mt-1">{ceo.position}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <section className='mt-6 mb-10 px-[38px] md:px-[50px] lg:px-[100px]'>
            <div className='container mx-auto p-4  items-center max-w-[68rem] px-4 sm:px-6 lg:px-8'>
                <p class="indent-6 md:indent-10 mb-5 text-[16px] md:text-[20px] leading-[28px] md:leading-[30px] text-[#1A191E] font-inter font-[500]">
                {/* Given the low wages, lack of casting opportunities, and massive hurdles to getting a fairly paid opportunity, 
                the entertainment industry is facing a crisis. Bureaucracy in the film and theater forces talented writers and
                 actors to burn out or give up on professions in the arts entirely. People aspiring for careers in entertainment
                  have struggled for decades just to get a fair shot at being cast in a film that’s larger than a local dishwasher 
                  commercial. Many writers have the potential to produce blockbuster films, but studio executives would rather take
                   a bet on someone with a reputation, than someone with a great script and few credits. With the odds already stacked 
                   against creative minds, the financial backing for stories penned by actual individuals now faces a new threat in the
                    form of artificial intelligence. */}
                    Given the low wages, lack of casting opportunities, and massive hurdles to getting a fairly paid opportunity, the entertainment industry is facing a crisis. Bureaucracy in the film and theater forces talented writers and actors to burn out or give up on professions in the arts entirely. People aspiring for careers in entertainment have struggled for decades just to get a fair shot at being cast in a film that’s larger than a local dishwasher commercial. Many writers have the potential to produce blockbuster films, but studio executives would rather take a bet on someone with a reputation, than someone with a great script and few credits. With the odds already stacked against creative minds, the financial backing for stories penned by actual individuals now faces a new threat in the form of artificial intelligence.
                </p>
                <p class="indent-6 md:indent-10 mb-5 text-[16px] md:text-[20px] leading-[28px] md:leading-[30px] text-[#1A191E] font-inter font-[500]">
                {/* After watching our exceptional actor and actress friends fight an uphill battle in an industry that rarely provided them with auditions, 
                we felt obligated to found Cast Me. Over the past year, we have met hundreds of talented individuals who have lost acting opportunities
                 to people with more privilege or financial resources than them. We aim to make auditioning more accessible for everyone, and provide
                  actors and writers with a platform they can trust when it comes to getting fairly paid roles in a quality film and theatrical production.   */}
                  After watching our exceptional actor and actress friends fight an uphill battle in an industry that rarely provided them with auditions, we felt obligated to found Cast Me. Over the past year, we have met hundreds of talented individuals who have lost acting opportunities to people with more privilege or financial resources than them. We aim to make auditioning more accessible for everyone, and provide actors and writers with a platform they can trust when it comes to getting fairly paid roles in a quality film and theatrical production.
                  </p>
                <p class="indent-6 mb-[94px] md:mb-[132px] text-[16px] md:text-[20px] leading-[28px] md:leading-[30px] text-[#1A191E] font-inter font-[500]">
                {/* We know that the best talent has yet to be discovered, and we’re going to change that. In the politically divisive times that we live in,
                 tv shows, theater, and films are an essential outlet for human connection. The arts can bridge the value gaps among humans of all backgrounds
                  in our country, and we’re on a mission to help bring new stories to life! We hope you’ll join us on journey to increase fairly paid
                   opportunities in entertainment! */}
                   Furthermore, we want to fund and source scripts from people whose stories would otherwise be ignored by big studios or agents. We know that the best talent has yet to be discovered, and we’re going to change that. TV shows, theater, and films are an essential outlet for human connection. The arts can bridge the value gaps among humans of all backgrounds in our country, and we’re on a mission to help bring new stories to life! We hope you’ll join us on journey to increase fairly paid opportunities in entertainment!
                   </p>
            </div>
            </section>
            <SubscribeSection />
            <Footer />
        </>
    )
}

export default AboutUs;